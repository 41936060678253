<template>
  <div class="about">
    <div>
      <el-input style="width: 300px;margin: 0 10px 0 0"  v-model="keywords"></el-input>
      <el-button @click="search()" type="primary" style="margin:10px 0;" icon="el-icon-search"></el-button>
    </div>
    <el-table  height="calc(100vh - 255px)" :data="tableData" style="width: 100%;">
      <el-table-column  align="center"
                        type="index"
                         width="55" label="#" >
      </el-table-column>
        <el-table-column prop="name" label="师傅等级名称" >
        </el-table-column>
      <el-table-column prop="withdraw_rule_name" label="提现规则" >
      </el-table-column>
      <el-table-column prop="level" label="等级" />
      <el-table-column prop="is_stop" label="停用">
        <template #default="scope">
          <el-tag type="" v-if="scope.row.is_stop == 1">是</el-tag>
          <el-tag type="" v-else>否</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="deposit_money" label="金额" />
      <!-- <el-table-column prop="mode" label="升级方式" >
        <template #default="scope">
           <span v-for="(item,i) in upgradeArr" :key="i">
             <span v-if="item.id==scope.row.upgrade_way">{{item.name}}</span>
           </span>
        </template>
      </el-table-column> -->
      <!-- <el-table-column prop="upgrade_growth_value" label="自动升级收益值" >
      </el-table-column> -->
      <el-table-column prop="description" label="等级描述" >
      </el-table-column>
      <el-table-column prop="address" label="操作" width="210">
        <template #header>
          <el-button size="mini" @click="add()" type="primary" style="margin:10px 0;">新增</el-button>
        </template>
        <template #default="scope">
          <div style="margin: 5px 0;">
            <el-button-group>
              <el-button size="mini" type="primary"  :disabled="scope.row.is_default == 1"  icon="el-icon-discount" @click="mr_default(scope.row)">默认等级</el-button>
              <el-button size="mini" type="primary" icon="el-icon-edit" @click="upData(scope.row)"></el-button>
              <el-button size="mini" type="primary" icon="el-icon-delete" @click="deleData(scope.row)"></el-button>
            </el-button-group>
          </div>
        </template>
      </el-table-column>
    </el-table>
      <div style="margin-top: 20px">
        <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[10, 20, 30, 40,50]"
            :page-size="limit"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        >
        </el-pagination>
      </div>
    <el-drawer
        title="新增/修改"
        v-model="drawer"
        size="50%"
        direction="rtl"
        :close-on-click-modal="false"
        destroy-on-close>
      <el-scrollbar height="calc(100vh - 160px)" style="height:calc(100% - 30px) ">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="125px"
          class="demo-ruleForm">
        <el-form-item label="师傅等级名称" prop="name" >
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="提现规则" prop="withdraw_rule_id">
          <el-select style="width: 100%"  v-model="ruleForm.withdraw_rule_id" >
            <el-option
                v-for="item in withdrawArr"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="升级方式" prop="upgrade_way">
          <el-select style="width: 100%"  v-model="ruleForm.upgrade_way" >
            <el-option
                v-for="item in upgradeArr"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item> -->
        <!-- <el-form-item label="自动升级收益值" prop="upgrade_growth_value">
          <el-input-number style="width: 100%" :min="0" controls-position="right" v-model="ruleForm.upgrade_growth_value"></el-input-number>
        </el-form-item> -->
        <el-form-item label="等级描述" prop="">
          <el-input  type="textarea" :rows="2" v-model="ruleForm.description"></el-input>
        </el-form-item>
        <el-form-item label="师傅保证金金额" prop="">
          <el-input  type="" :rows="2" v-model="ruleForm.deposit_money"></el-input>
        </el-form-item>
        <!-- <el-form-item label="是否必须要缴纳保证金" prop="">
          <el-select style="width: 100%"  v-model="ruleForm.is_necessary" >
            <el-option
                v-for="item in is_necessary"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="是否停用" prop="">
          <el-select style="width: 100%"  v-model="ruleForm.is_stop" >
            <el-option
                v-for="item in is_stop"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="等级" prop="">
          <el-input  type="" :rows="2" v-model="ruleForm.level"></el-input>
        </el-form-item>

        <div style="color: #606266;display: flex;justify-content: space-between"><span>● 师傅佣金规则</span><el-button size="medium" type="text" @click="addincome()"> 添加师傅佣金规则</el-button></div>
        <el-table  max-height="300px" :data="ruleForm.income_rule" style="width: 100%;margin:10px 0 30px 0">
          <el-table-column  type="index" label="#" width="60">
          </el-table-column>
          <el-table-column prop="name" label="规则名称" min-width="110">
          </el-table-column>
          <el-table-column fixed="right" prop="unit" label="操作" width="57">
            <template #default="scope">
              <el-button size="medium" type="text" @click="deletincome(scope.row,scope.$index)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
      </el-scrollbar>
      <div style="text-align: right">
        <el-button type="primary" v-if="ruleForm.id" :loading="login" @click="okAdd('ruleForm',2)">修改</el-button>
        <el-button type="primary" v-else :loading="login" @click="okAdd('ruleForm',1)">保存</el-button>
      </div>
      <el-drawer
          title="新增师傅佣金规则"
          size="40%"
          :append-to-body="true"
          v-model="LabeDrawerInc" >
        <el-scrollbar style="height: 90%" height="600px"  >
          <div>
            <el-input size="mini" style="width: 300px;margin: 0 10px 0 0"  v-model="Inckeywords"></el-input>
            <el-button size="mini" @click="incsearch()" type="primary" style="margin:10px 0;" icon="el-icon-search"></el-button>
          </div>
          <el-table :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"  @select="incChange"  max-height="450px" :data="InckListarr" default-expand-all row-key="id" style="width: 100%;margin:10px 0 30px 0">
            <el-table-column
                type="selection"
                width="55">
            </el-table-column>
            <el-table-column prop="name" label="佣金规则名称" min-width="120">
            </el-table-column>
          </el-table>
          <el-pagination
              small
              background
              :pager-count="5"
              @size-change="InsSizeChange"
              @current-change="InsCurrentChange"
              :current-page="Incpage"
              :page-sizes="[10, 20, 30, 40,50]"
              :page-size="Inclimit"
              layout="total, sizes, prev, pager, next, jumper"
              :total="Inctotal">
          </el-pagination>
        </el-scrollbar>
        <el-button type="primary" @click="okaddInc()">确定</el-button>
      </el-drawer>
    </el-drawer>
  </div>
</template>
<script>
// @ is an alias to /src
import { getArea } from "@/api/common";
import * as axios from "@/api/skuApi"//本页面接口
export default {
  name: 'Grade',
  data(){
    return{
      /*规则--*/
      InckListarr:[],//规则组
      InckList:[],//规则
      nowInckList:[],//选中规则
      LabeDrawerInc:false,//规则
      Incpage:1,
      Inclimit:10,
      Inctotal:0,
      Inckeywords:'',
      /*规则--*/

      skuValName:'',
      login:false,
      drawer:false,
      tableData:[],
      areaArr:[],//城市
      page:1,
      limit:10,
      total:0,

      keywords:'',
      ruleForm:{
        name:'',
        grade_type:0,
        withdraw_rule_id:'',
        upgrade_growth_value:0,
        upgrade_way:0,
        description:'',
        income_rule:[],
        deposit_money:'',
        is_necessary:'',
        is_stop:'',
        level:0
      },
      gradeArr:[
        {
          id:0,
          name:'师傅等级',
        },
        {
          id:1,
          name:'员工等级',
        },
      ],
      upgradeArr:[
        {
          id:0,
          name:'自动升级',
        },
        {
          id:1,
          name:'手动升级',
        },
      ],
      withdrawArr:[],
      rules:{
        name: [
          { required: true, message: '请填写名称', trigger: 'blue' },
        ],
        sale_price: [
          { required: true, message: '请填写名称', trigger: 'blue' },
        ],
        withdraw_rule_id: [
          { required: true, message: '请填写', trigger: 'blue' },
        ],
        upgrade_way: [
          { required: true, message: '请选择', trigger: 'blue' },
        ],
        upgrade_growth_value: [
          { required: true, message: '请填写', trigger: 'blue' },
        ],
        unit: [
          { required: true, message: '请填写单位', trigger: 'blue' },
        ],
      },
      is_necessary:[
        {name:"是",id:1},
        {name:"否",id:0}
      ],
      is_stop:[
        {name:"是",id:1},
        {name:"否",id:0}
      ]
    }
  },
  created() {
    this.onTable()
    this.onArea()
    this.onwithdrawArr()
    // this.keyupSubmit()
  },
  methods:{
    // keyupSubmit(){
    //   document.onkeydown=e=>{
    //     let _key=window.event.keyCode;
    //     if(_key===13){
    //       this.search()
    //     }
    //   }
    // },

    /*指定规则*/
    incsearch(){
      this.Incpage=1
      this.onIncomdata()
    },
    onIncomdata(){
      let data={
        page:this.Incpage,
        limit:this.Inclimit,
        keywords:this.Inckeywords,
      }
      axios.getSkuData(data,'/api/admin/workerIncomeRule').then((res)=>{
        console.log(res)
        this.InckListarr=res.data.data
        this.Inctotal=res.data.total
      }).catch((err)=>{

      })
    },
    addincome(){
      this.onIncomdata()
      this.LabeDrawerInc=true
    },
    InsSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.Inclimit=val
      this.onIncomdata()
    },
    InsCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.Incpage=val
      this.onIncomdata()
    },
    incChange(selection,row){
      var newNum1 = this.ruleForm.income_rule.find((item, index) => {
        return item.income_rule_id == row.id;
      })
      if(newNum1){
        console.log(newNum1)
      }else {
        this.ruleForm.income_rule.push({income_rule_id:row.id,name:row.name})
      }
    },
    okaddInc(){
      this.LabeDrawerInc=false
    },
    deletincome(row,i){
      if(row.id){
        this.$confirm('删除!!, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          axios.postSkuData({id:row.id},'/api/admin/workerGrade/delGradeRules').then((res)=>{
            if(res.code==200){
              this.$message.success(res.msg)
              this.ruleForm.income_rule.splice(i,1)
            }else {
              this.$message.error(res.msg)
            }
          }).catch((err)=>{
            this.$message.error('服务器报错！！请联系管理员')
          })
        })
            .catch(() => {
              this.$message({
                type: 'error',
                message: '已取消删除',
              })
            })
      }else {
        this.ruleForm.income_rule.splice(i,1)
      }
    },
    /*-----------*/

    onArea(){
        getArea().then((res)=>{
          this.areaArr=res.data
        }).catch((err)=>{

        })
    },
    onwithdrawArr(){
      let form={
        page:1,
        limit:500,
      }
      axios.getSkuData(form,'/api/admin/member/withdraw').then((res)=>{
        console.log(res)
        this.withdrawArr=res.data.data
      }).catch((err)=>{

      })
    },
    onTable(){
      let form={
        page:this.page,
        limit:this.limit,
        keywords:this.keywords,
      }
        axios.getSkuData(form,'/api/admin/workerGrade').then((res)=>{
          console.log(res)
          this.tableData=res.data.data
          console.log('this.tableData',this.tableData)
          this.total=res.data.total
        }).catch((err)=>{

        })
    },
    search(){
      this.page=1
      this.onTable()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.limit=val
      this.onTable()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.page=val
      this.onTable()
    },


    add(){
      this.ruleForm={
        name:'',
        grade_type:0,
        withdraw_rule_id:'',
        upgrade_way:0,
        upgrade_growth_value:0,
        description:'',
        income_rule:[],
      }
      this.drawer=true
    },
    upData(row){
      // console.log(row)
      this.ruleForm.id=row.id
      this.ruleForm.name=row.name
      this.ruleForm.grade_type=row.grade_type
      this.ruleForm.withdraw_rule_id=row.withdraw_rule_id
      this.ruleForm.description=row.description
      this.ruleForm.upgrade_growth_value=Number(row.upgrade_growth_value)
      this.ruleForm.income_rule=row.income_rule
      this.ruleForm.upgrade_way=row.upgrade_way
      
      this.ruleForm.deposit_money=row.deposit_money
      this.ruleForm.is_necessary=row.is_necessary
      this.ruleForm.is_stop=row.is_stop
      this.ruleForm.level=row.level

      this.drawer=true
    },

    okAdd(formName,val){
      if(this.login){
        return false
      }
      this.login=true
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(val==1){
            axios.postSkuData(this.ruleForm,'/api/admin/workerGrade/add').then((res)=>{
                if(res.code==200){
                  this.$message.success(res.msg)
                  this.onTable()
                  let that=this
                  setTimeout(function () {
                    that.drawer=false
                    that.login=false
                  },1000)
                }else {
                  this.login=false
                  this.$message.error(res.msg)
                }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }else {
            axios.postSkuData(this.ruleForm,'/api/admin/workerGrade/edit').then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                this.login=false
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.login=false
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }
        } else {
          this.login=false
          console.log('error submit!!')
          return false
        }
      })
    },
    deleData(row){
      this.$confirm('删除!!, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {
            axios.postSkuData({id:row.id},'/api/admin/workerGrade/del').then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.$message.error('服务器报错！！请联系管理员')
            })
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: '已取消删除',
            })
          })
    },
    async mr_default(row){
      const res = await axios.postSkuData({id:row.id},'/api/admin/worker/is_default')
      if(res.code == 200){
        this.$message.success(res.msg)
        this.onTable()
      }
    }
  }
}
</script>
<style lang="less" >

</style>